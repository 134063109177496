import React from "react"
import Layout from "../components/layout"
import LightLogo from "../components/lightlogo"

const NotFoundPage = () => (
  <Layout style={{ backgroundColor: `black`, height: `100vh`, textAlign: `center`, padding: `10vw` }}
    lang="en" title="HAG Studio" link="https://www.hag.studio/404">
    <LightLogo style={{ width: `10vh`, margin: `auto` }} />
    <h1 style={{ color: `#ddd`, marginTop: `1em` }}>NOT FOUND</h1>
    <p>Maybe the page that you were looking for is no longer available. To go back to the main page click in the link below:</p>
    <a href="/">Go back</a>
  </Layout>
)

export default NotFoundPage
